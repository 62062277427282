import { orderBy } from 'lodash';
import dateLib from '@wix/communities-blog-client-common/dist/src/helpers/date-lib-wrapper';

export const buildArchiveLinks = (postStatsByDate, newestFirst = true) => {
  const stats = orderBy(postStatsByDate, ['periodStart'], [newestFirst ? 'desc' : 'asc']);
  const links = stats.map(({ periodStart, publicationsCount }) => {
    const date = dateLib(periodStart);
    return { path: `/archive/${date.format('YYYY/MM')}`, text: date.format('MMMM YYYY'), postCount: publicationsCount };
  });
  return links;
};
