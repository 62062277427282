import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import withTranslate from '../../../common/hoc/with-translate';
import LinkList from '../../../link-list';
import { getShowPostCount, getArchiveLinks } from '../../selectors/archive-selectors';

const ARCHIVE_LIMIT = 50;

const Archive = ({ t, archiveLinks, showPostCount }) => (
  <LinkList
    links={archiveLinks.slice(0, ARCHIVE_LIMIT).map(archiveLink => ({
      path: archiveLink.path,
      text: showPostCount
        ? `${archiveLink.text} ${t('archive-link.post-count', { count: archiveLink.postCount })}`
        : archiveLink.text,
    }))}
    emptyState={{ text: t('empty-state.no-posts') }}
  />
);

Archive.propTypes = {
  t: PropTypes.func.isRequired,
  archiveLinks: PropTypes.array.isRequired,
  showPostCount: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = state => ({
  archiveLinks: getArchiveLinks(state),
  showPostCount: getShowPostCount(state),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(Archive);
